import { Injectable } from '@angular/core';
import { GuardsCheckEnd, NavigationEnd, ResolveStart, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { getRouteParams } from 'shared/utils';

@Injectable({ providedIn: 'root' })
export class ResolverMonitorService {

  resolving: string | null = null;
  params = {} as { [key: string]: string };

  constructor(
    router: Router,
  ) {
    router.events.pipe(
      tap(e => {
        if (e instanceof GuardsCheckEnd && e.shouldActivate) {
          this.params = getRouteParams(e.state.root);
        }
        if (e instanceof ResolveStart) {
          this.resolving = e.url;
        }
        if (e instanceof NavigationEnd) {
          this.resolving = null;
        }
      }),
    ).subscribe();
  }

}
