import { NgModule } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { G2I_AUTH_CONFIG, G2iAuthConfiguration, G2iAuthService, StaffProfileAuthGuard } from 'g2i-ng-auth';
import { configure, defaultDispatchTagPreprocessor } from 'g2i-ngrx-utils';
import { environmentOverride, providerOverrides } from 'shared/api/api.consts';
import { ResolverMonitorService } from 'shared/api/resolver-monitor';
import { ApiScheduleService } from 'shared/api/schedule/schedule/api-schedule.service';
import {
  APP_ENVIRONMENT_BASE_URL,
  DEVELOPING_ON_LOCALHOST,
  HEADER_COLOR,
  STATE_MANAGERS,
  SUPPORT_ORG_ID_IN_URL,
} from 'shared/consts';
import { AppRoute, shellRoutes } from 'shared/modules/shell-app/consts';
import { ShellAppGuard } from 'shared/modules/shell-app/shell-app.guard';
import { ShellAppModule } from 'shared/modules/shell-app/shell-app.module';
import { ShellSnackbarModule } from 'shared/modules/shell-snackbar/snackbar.module';
import { OverlayService } from 'shared/overlays/overlay.service';

import { environment } from '../environments/environment';
import { ApiSchedulePersonalService } from '../shared/api/schedule-personal/api-schedule-personal.service';
import { getReducers, managers, reducerToken } from '../shared/store/core';
import { AppState } from '../shared/store/interfaces';
import { AppComponent } from './app.component';
import { MainResolver } from './main/main.resolver';

dayjs.extend(utc);
dayjs.extend(timezone);

const authConfig: G2iAuthConfiguration = {
  authServiceUrl: environment.isLocalhost
    ? providerOverrides.auth || environmentOverride || environment.baseApiUrl
    : environment.baseApiUrl,
  staffServiceUrl: environment.isLocalhost
    ? providerOverrides.staff || environmentOverride || environment.baseApiUrl
    : environment.baseApiUrl,
  cookieName: environment.authCookieName,
  providers: ['default'],
  debug: false,
  logo: '/assets/guud-logo.svg',
  primaryColor: '#1E2334',
  snackbarCallback: (message: string, icon: string, isError: boolean, action?: string) => {
    (this as unknown as { matSnackbar: MatSnackBar }).matSnackbar.show({ status: isError ? 'error' : 'success', message });
  },
};

const routes: AppRoute[] = [
  ...shellRoutes,
  {
    path: 'app',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
    canActivate: [ShellAppGuard, StaffProfileAuthGuard],
    resolve: [MainResolver],
  },
  {
    path: '**',
    redirectTo: 'landing',
  },
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ShellSnackbarModule,
    BrowserModule,
    ShellAppModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' }),
    StoreModule.forRoot(reducerToken, {
      runtimeChecks: {
        strictActionImmutability: true,
        strictActionSerializability: true,
        strictStateImmutability: true,
        strictStateSerializability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      name: 'xguud-staff',
      maxAge: 25,
    }),
  ],
  providers: [
    MainResolver,
    ApiSchedulePersonalService,
    ApiScheduleService,
    OverlayService,
    {
      provide: G2I_AUTH_CONFIG,
      useValue: authConfig,
    },
    {
      provide: STATE_MANAGERS,
      useValue: managers,
    },
    {
      provide: APP_ENVIRONMENT_BASE_URL,
      useValue: environment.baseApiUrl,
    },
    {
      provide: SUPPORT_ORG_ID_IN_URL,
      useValue: false,
    },
    {
      provide: reducerToken,
      useFactory: getReducers,
    },
    {
      provide: DEVELOPING_ON_LOCALHOST,
      useValue: environment.isLocalhost,
    },
    {
      provide: HEADER_COLOR,
      useValue: 'secondary',
    },
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {
  constructor(
    store: Store<AppState>,
    resolverMonitor: ResolverMonitorService, // please do not remove this constructor arg.
    private authService: G2iAuthService,
    private readonly snackbar: MatSnackBar,
    router: Router,
  ) {
    // Ensure candeactivate guard works correctly on back press (https://github.com/angular/angular/issues/13586#issuecomment-872592435)
    // @ts-ignore: private option not yet exposed for public use
    router.canceledNavigationResolution = 'computed';

    configure({
      dispatchFunction: (arg: any) => store.dispatch(arg),
      dispatchTagPosition: 'append',
      dispatchTagPreprocessor: defaultDispatchTagPreprocessor,
    });

    this.authService.config.snackbarCallback = (message, icon, isError, action) => {
      this.snackbar.show({ status: isError ? 'error' : 'success', message });
    };
  }
}
