import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class OverlayService {
  public readonly geofenceEventIdVisible$ = new BehaviorSubject<string>(null);
  public readonly geofenceRadiusInKm$ = new BehaviorSubject<number>(100);


  constructor() {

  }


}
