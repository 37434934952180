import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { G2iAuthService } from 'g2i-ng-auth';
import { Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, take, tap } from 'rxjs/operators';
import { APP_ENVIRONMENT_BASE_URL, DEVELOPING_ON_LOCALHOST, STATE_MANAGERS } from 'shared/consts';
import { navigateToErrorPage } from 'shared/utils';

import { environmentOverride, providerOverrides, services } from './api.consts';
import { ResolverMonitorService } from './resolver-monitor';

export class ApiBaseService {

  private readonly httpClient = this.injector.get(HttpClient);
  private readonly authService = this.injector.get(G2iAuthService);
  private readonly stateManagers = this.injector.get(STATE_MANAGERS);
  private readonly router = this.injector.get(Router);
  private readonly resolverMonitor = this.injector.get(ResolverMonitorService);

  constructor(
    private readonly baseUrl: () => string,
    private readonly injector: Injector,
  ) {
  }

  protected routeParam(paramName: 'organizationid' | 'clientId' | 'reportId' | 'scheduleKey' | 'projectId') {
    return this.resolverMonitor.params[paramName];
  }

  protected serviceUrl(service: typeof services[number]) {
    return this.injector.get(DEVELOPING_ON_LOCALHOST)
      ? providerOverrides[service] || environmentOverride || this.injector.get(APP_ENVIRONMENT_BASE_URL)
      : this.injector.get(APP_ENVIRONMENT_BASE_URL);
  }

  protected request<O>(
    endpoint: string | number,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'UPLOAD' | 'DOWNLOAD' | 'DOWNLOAD_ATTACHMENT',
    params: { [key: string]: any } = {},
    extras?: {
      unauthenticated?: boolean;
      disallowRedirectToErrorPage?: boolean;
    }
  ): Observable<O> {

    return this.authService.authDetails$.pipe(
      take(1),
      concatMap(authDetails => {

        // Construct URL
        const baseUrl = this.baseUrl();

        // Ensure no double-slash on URL
        const slashBase = baseUrl.endsWith('/');
        const slashEndpoint = typeof endpoint === 'string' && endpoint.startsWith('/');
        const slash = slashBase || slashEndpoint ? '' : '/';

        // Generate final URL
        const url = `${baseUrl}${endpoint ? (slash + endpoint) : ''}`;

        // Define headers
        const headers = extras?.unauthenticated
          ? { }
          : { ...this.authService.getAuthHeader(), 'x-bb-sub': authDetails.userId };

        // Prune params which are null or undefined
        if (params) {
          Object.keys(params)
            .filter(key => [null, undefined].some(val => params[key] === val))
            .forEach(key => delete params[key]);
        }

        // If downloading a file which can be directly viewed in the browser (eg an image)
        if (method === 'DOWNLOAD') {
            return this.httpClient.get(url, { params, responseType: 'arraybuffer', headers }) as any as Observable<O>;
        }

        // If downloading an attachment which cannot be directly viewed in the browser (eg a CSV report)
        if (method === 'DOWNLOAD_ATTACHMENT') {
          return this.httpClient.get(url, { params, responseType: 'blob', observe: 'response', headers })
            .pipe(
              tap(data => {
                const contentDisposition = data.headers.get('Content-disposition');
                const body = data.body;
                if (!body) { throw new Error(); }
                Object.assign(document.createElement('a'), {
                  href: window.URL.createObjectURL(new Blob([body])),
                  target: '_blank',
                  download: contentDisposition.substring('attachment;filename='.length),
                }).click();
              }),
            ) as any as Observable<O>;
        }

        // If uploading a file
        if (method === 'UPLOAD') {
          const { file, ...otherParams } = params;
          const formData: FormData = new FormData();
          formData.append('file', file, file.name);
          Object.keysTyped(otherParams).filter(key => key !== 'file').forEach(key => formData.append(key as string, otherParams[key]));
          return this.httpClient.post<O>(url, formData, { headers });
        }

        // If doing a standard GET request
        if (method === 'GET') {
          return this.httpClient.get<O>(url, { params, headers }).pipe(
            catchError((e: HttpErrorResponse, uncaught) => {
              const appHasntYetLoaded = !this.stateManagers.currentState().shell.appHasLoaded;
              if (!extras?.disallowRedirectToErrorPage && (appHasntYetLoaded || this.resolverMonitor.resolving)) {
                navigateToErrorPage(this.router, appHasntYetLoaded ? '/app/' : this.resolverMonitor.resolving, e.status);
                return of(null) as any as typeof uncaught;
              } else {
                return throwError(e) as any as typeof uncaught;
              }
            }),
          );
        }

        // If doing a standard POST / PUT / DELETE / PATCH request
        return this.httpClient.request<O>(method, url, { body: params, headers });
      }),
    );
  }
}
