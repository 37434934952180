import { manageStateSlice } from 'g2i-ngrx-utils';

export const shellManager = manageStateSlice({
  resolverErrorReported: null as null | null | { code: number; message?: string },
  appHasLoaded: false,
  contentWrapperOverflowY: null as 'auto' | 'hidden' | null,
  refreshSideMenuImage: true,
  breadcrumbVars: {} as { [key: string]: string },
  deeplinkParentUrl: null as string,
  disableDefaultLoader: false,
})({
  contentWrapperOverflowY: (state, payload: 'auto' | 'hidden' | null) => {
    state.contentWrapperOverflowY = payload;
  },
  deeplinkParentUrl: (state, payload: string) => {
    state.deeplinkParentUrl = payload;
  },
  disableDefaultLoader: (state, payload: boolean) => {
    state.disableDefaultLoader = payload;
  },
  /** Please do not call this action. It is used internally by the app shell */
  resolverErrorReported: (state, payload: null | { code: number; message?: string }) => {
    state.resolverErrorReported = payload;
  },
  /** Please do not call this action. It is used internally by the app shell */
  appHasLoaded: (state, payload: boolean) => {
    state.appHasLoaded = payload;
  },
  /** Please do not call this action. It is used internally by the app shell */
  refreshSideMenuImage: (state, payload: boolean) => {
    state.refreshSideMenuImage = payload;
  },
  /** Please do not call this action. It is used internally by the app shell */
  breadcrumbVars: (state, payload: { [key: string]: string }) => {
    state.breadcrumbVars = payload;
  },
});
