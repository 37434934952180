import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { fromEvent, Subscription } from 'rxjs';
import { filter, startWith, tap } from 'rxjs/operators';
import { STATE_MANAGERS } from 'shared/consts';
import { ShellStateManagers } from 'shared/store/interfaces';
import { shellManager } from 'shared/store/shell-manager';
import { moveActionsIntoPageHeader, observeProperty, removePageActionsFromPageHeader } from 'shared/utils';

@Component({
  selector: 'app-shell-page',
  templateUrl: './shell-page.component.html',
  styleUrls: ['./shell-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellPageComponent implements AfterViewInit, OnDestroy {

  @ViewChild('headerActions')
  readonly headerActions!: ElementRef<HTMLDivElement>;
  @ViewChild('search')
  readonly search!: ElementRef<HTMLDivElement>;
  @ViewChild('title')
  readonly title!: ElementRef<HTMLDivElement>;
  @ViewChild('actions')
  readonly actions!: ElementRef<HTMLDivElement>;
  @Input()
  breadcrumbVars = {} as { [key: string]: string };
  readonly subscriptions: Subscription[] = [];
  readonly contentWrapperOverflowY$ = this.store.select(s => s.shell.contentWrapperOverflowY);
  errorMessage!: string;
  errorDescription?: string;

  constructor(
    @Inject(STATE_MANAGERS) readonly stateManagers: ShellStateManagers,
    private readonly store: Store<{ shell: typeof shellManager.initialState }>,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {
    this.subscriptions.push(observeProperty(this, 'breadcrumbVars').pipe(
      filter(vars => !!Object.keys(vars).length),
      tap(() => shellManager.dispatch.breadcrumbVars(__filename, this.breadcrumbVars)),
    ).subscribe());
  }

  ngAfterViewInit() {
    moveActionsIntoPageHeader(this.headerActions);
    if (this.search.nativeElement) {
      this.subscriptions.push(this.updateSearchBoxWidth());
    }
  }

  ngOnDestroy() {
    removePageActionsFromPageHeader(this.headerActions);
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onClickBreadcrumb(path: string) {
    this.router.navigateByUrlNoHistory(this.route, path);
  }

  private updateSearchBoxWidth() {
    return fromEvent(window, 'resize').pipe(
      startWith({}),
      tap(() => {
        const totalWidth = window.innerWidth;
        const leftWidth = this.title.nativeElement.getBoundingClientRect().left + this.title.nativeElement.getBoundingClientRect().width;
        const rightWidth = totalWidth - this.actions.nativeElement.getBoundingClientRect().left;
        const search = this.search.nativeElement;
        const searchWidthMax = 650;
        if (Math.max(leftWidth, rightWidth) < ((totalWidth - searchWidthMax) / 2)) {
          search.style.position = 'absolute';
          search.style.left = `${(totalWidth - searchWidthMax) / 2}px`;
          search.style.width = searchWidthMax + 'px';
          search.style.marginLeft = '0';
        } else {
          search.style.position = 'initial';
          search.style.flex = '1';
          search.style.width = 'initial';
          search.style.left = 'initial';
          search.style.marginLeft = '8px';
        }
      }),
    ).subscribe();
  }

}
