import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorService extends ErrorHandler {

  constructor(
    private readonly injector: Injector,
  ) {
    super();
  }

  public handleError(error: any) {
    super.handleError(error);
    this.injector.get(MatSnackBar).showErrorUnexpected('Something unexpected happened.');
  }
}
