import { InjectionToken } from '@angular/core';
import { ShellStateManagers } from './store/interfaces';

type LookupId<T> = T extends { [key: string]: { id: infer I } } ? I : never;
type LookupName<T> = T extends { [key: string]: { name: infer I } } ? I : never;
type LookupById<T extends { [key: string]: { id: unknown } }, X> =
  T extends { [key: string]: infer I } ? I extends { id: infer W } ? W extends X ? I : never : never : never;
type LookupByName<T extends { [key: string]: { name: string } }, X> =
  T extends { [key: string]: infer I } ? I extends { name: infer W } ? W extends X ? I : never : never : never;

const defineLookup = <K extends (number | string), T extends { [key: string]: { id: K; name: string } }>(arg: T) => ({
  /**
   * Returns an array of { value: number | string, label: string } for template controls (eg mat-select) to consume
   */
  $htmlOptions: Object.valuesTyped(arg).map(e => ({ value: e.id, label: e.name })),
  /**
   * Returns an array of possible IDs for this lookup.
   *
   * Can also be used to strongly type a lookup table ID:
   *
   * @example
   * interface User {
   *   name: string;
   *   age: number;
   *   userRoleIds: typeof userRoles.$ids,
   *   userTypeId: typeof userTypes.$ids[0];
   * }
   */
  $ids: Object.valuesTyped(arg).map(e => e.id) as LookupId<T>[],
  /**
   * Returns an array of possible names for this lookup
   */
  $names: Object.valuesTyped(arg) as LookupName<T>[],
  /**
   * Returns an array of possible values for this lookup
   */
  $values: Object.valuesTyped(arg),
  /**
   * Attempts to find a lookup entry from the supplied 'id', else returns undefined
   */
  $findById: <X extends LookupId<T>>(id: X) => Object.valuesTyped(arg).find(e => e.id === id) as LookupById<T, X>,
  /**
   * Attempts to find a lookup entry from the supplied 'name', else returns undefined
   */
  $findByName: <X extends LookupName<T>>(id: X) => Object.valuesTyped(arg).find(e => e.id === id) as LookupByName<T, X>,
  ...arg,
});

export const scripts = {
  googleMaps: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDeFPhXhwp-9k9ORh1v0tWMTT4Z2dw0Wyc&libraries=places,geometry',
  what3Words: 'https://assets.what3words.com/sdk/v3/what3words.js?key=KP1AVQ3T',
} as const;

export const appPermissions = defineLookup({
  backboneOrganizationWrite: {
    id: 1,
    name: 'backbone:organization:write'
  },
  backboneOrganizationRead: {
    id: 2,
    name: 'backbone:organization:read'
  },
  backboneUserWrite: {
    id: 3,
    name: 'backbone:user:write'
  },
  backboneUserRead: {
    id: 4,
    name: 'backbone:user:read'
  },
  organizationSelfWrite: {
    id: 10,
    name: 'organization:self:write'
  },
  organizationUserWrite: {
    id: 13,
    name: 'organization:user:write'
  },
  organizationUserRead: {
    id: 14,
    name: 'organization:user:read'
  },
  placeWrite: {
    id: 20,
    name: 'place:write'
  },
  gencapBackoffice: {
    id: 101,
    name: 'gencap:backoffice'
  },
  gencapMobile: {
    id: 102,
    name: 'gencap:mobile'
  },
  vehicleWrite: {
    id: 110,
    name: 'vehicle:write'
  },
  vehicleLeaseWrite: {
    id: 112,
    name: 'vehicle_lease:write'
  },
  scheduleProjectWrite: {
    id: 121,
    name: 'schedule:project:write'
  },
  scheduleServiceblockWriteFull: {
    id: 123,
    name: 'schedule:serviceblock:write:full',
  },
  reportingReportWrite: {
    id: 125,
    name: 'reporting:report:write'
  },
} as const);

export const apps = defineLookup({
  assetPartner: {
    id: 101,
    name: 'Asset partner',
    url: 'assets.guudapp.co',
  },
  implementationPartner: {
    id: 102,
    name: 'Implementation partner',
    url: 'schedules.guudapp.co',
  },
  fyne: {
    id: 121,
    name: 'Fyne',
    url: 'app.fyneforms.co',
  },
  reporting: {
    id: 111,
    name: 'Reporting',
    url: 'reporting.guudapp.co',
  },
  client: {
    id: 103,
    name: 'Client',
    url: 'client.guudapp.co',
  }
} as const);

export const orgTypes = defineLookup({
  assetPartner: {
    id: 1,
    name: 'Asset partner'
  },
  implementationPartner: {
    id: 2,
    name: 'Implementation partner'
  },
  serviceClient: {
    id: 3,
    name: 'Service Client'
  },
  dataCollector: {
    id: 4,
    name: 'Data Collector'
  },
} as const);

export const geofenceTypes = defineLookup({
  depotEnter: {
    id: 1,
    name: 'Depot Enter',
    description: (vehicleName: string, placeName: string) =>
      `<span class='vehicle'>${vehicleName}</span> arrival at <span class='depot'>${placeName}</span> depot`
  },
  depotExit: {
    id: 2,
    name: 'Depot Exit',
    description: (vehicleName: string, placeName: string) =>
      `<span class='vehicle'>${vehicleName}</span> departure from <span class='depot'>${placeName}</span> depot`
  },
  serviceBlockEnter: {
    id: 3,
    name: 'ServiceBlock Enter',
    description: (vehicleName: string, placeName: string) =>
      `<span class='vehicle'>${vehicleName}</span> arrival on site <span class='depot'>${placeName}</span>`
  },
  serviceBlockExit: {
    id: 4,
    name: 'ServiceBlock Exit',
    description: (vehicleName: string, placeName: string) =>
      `<span class='vehicle'>${vehicleName}</span> departure from site <span class='depot'>${placeName}</span>`
  },
} as const);

export const serviceBlockStatuses = defineLookup({
  toAction: {
    id: 1,
    name: 'To action',
    color: '#ECB52B',
    ordinal: 1,
  },
  schedule: {
    id: 2,
    name: 'Scheduled',
    color: '#043A7D',
    ordinal: 2,
  },
  inProgress: {
    id: 3,
    name: 'In progress',
    color: '#00B4D8',
    ordinal: 3,
  },
  new: {
    id: 4,
    name: 'New',
    color: '#ECB52B',
    ordinal: 0,
  },
  cancelled: {
    id: 98,
    name: 'Cancelled',
    color: '#B8B8B8',
    ordinal: 5,
  },
  completed: {
    id: 99,
    name: 'Completed',
    color: '#06B88A',
    ordinal: 4,
  },
} as const);

export const scheduleStatuses = defineLookup({
  unready: {
    id: 1,
    name: 'Unready',
    color: '#ECB52B',
    ordinal: 1,
  },
  ready: {
    id: 2,
    name: 'Ready',
    color: '#043A7D',
    ordinal: 2,
  },
  active: {
    id: 3,
    name: 'Active',
    color: '#00B4D8',
    ordinal: 3,
  },
  completed: {
    id: 4,
    name: 'Completed',
    color: '#06B88A',
    ordinal: 4,
  },
  cancelled: {
    id: 5,
    name: 'Cancelled',
    color: '#B8B8B8',
    ordinal: 5,
  },
} as const);

export const jobStatus = defineLookup({
  acknowledged: {
    id: 1,
    name: 'Acknowledged'
  },
  declined: {
    id: 2,
    name: 'Declined'
  },
  pendingAcknowledgement: {
    id: 3,
    name: 'Pending acknowledgement'
  },
  picked: {
    id: 4,
    name: 'Picked'
  },
  completed: {
    id: 99,
    name: 'Completed'
  }
} as const);

export const staffTypes = defineLookup({
  operator: {
    id: 1,
    name: 'Operator'
  },
  optometrist: {
    id: 2,
    name: 'Optometrist'
  },
  nurse: {
    id: 3,
    name: 'Nurse'
  },
  dentalAssistant: {
    id: 4,
    name: 'Dental assistant'
  },
  dentalGeneralPractitioner: {
    id: 5,
    name: 'Dental general practitioner'
  },
  generalPractitioner: {
    id: 6,
    name: 'General practitioner'
  },
  audiologist: {
    id: 7,
    name: 'Audiologist'
  },
} as const);

export const stepCriteria = defineLookup({
  default: { // TODO: remove
    id: 0,
    name: 'Reason',
    description: 'Here is a reason',
  },
  where: {
    id: 1,
    name: 'Where',
    description: 'Where',
  },
  when: {
    id: 2,
    name: 'When',
    description: 'When',
  },
} as const);

export const stepExemptionType = defineLookup({
  whereAutocompleted: {
    id: 12,
    name: 'Where autocompleted',
    description: 'Where autocompleted',
    stepCriteriaId: stepCriteria.where.id,
  },
  whenAutocompleted: {
    id: 13,
    name: 'When autocompleted',
    description: 'When autocompleted',
    stepCriteriaId: stepCriteria.when.id,
  },
} as const);

export const stepExemptionReason = defineLookup({
  whereAutocompleted: {
    id: 100,
    name: 'Where autocompleted',
    stepExemptionTypeId: stepExemptionType.whereAutocompleted.id,
  },
  whenAutocompleted: {
    id: 101,
    name: 'Where autocompleted',
    stepExemptionTypeId: stepExemptionType.whenAutocompleted.id,
  },
} as const);

export const changeRequestResolutions = defineLookup({
  declined: {
    id: 1,
    name: 'Declined',
  },
  accepted: {
    id: 2,
    name: 'Accepted'
  },
} as const);

export const trackingProviders = defineLookup({
  cartrack: {
    id: 1,
    name: 'Cartrack',
    icon: 'cartrack.png',
  }
} as const);

export const trackerVehicleMatchingStatus = defineLookup({
  newlyMatched: {
    id: 'NEWLY_MATCHED',
    name: 'Newly matched',
    color: '#06B88A',
  },
  alreadyMatched: {
    id: 'ALREADY_MATCHED',
    name: 'Already matched',
    color: '#A8A8A8',
  },
  unmatched: {
    id: 'UNMATCHED',
    name: 'Unmatched',
    color: '#ECB52B',
  }
} as const);

export const httpErrors = {
  400: {
    title: 'Bad request'
  },
  401: {
    title: 'Unauthorized'
  },
  403: {
    title: 'Not allowed',
    description: 'You do not have the required permissions'
  },
  404: {
    title: 'Not found'
  },
  409: {
    title: 'Conflict',
  },
  500: {
    title: 'Unknown error'
  },
  502: {
    title: 'Bad gateway'
  },
  503: {
    title: 'Service unavailable'
  },
} as { [key: number]: { title: string; description?: string } };

export const PERMISSION_REQUIRED_FOR_APP_ACCESS = new InjectionToken<string>('PERMISSION_REQUIRED_FOR_APP_ACCESS');
export const STATE_MANAGERS = new InjectionToken<ShellStateManagers>('STATE_MANAGERS');
export const HEADER_COLOR = new InjectionToken<'primary' | 'accent' | 'warn'>('HEADER_COLOR');
export const APP_ENVIRONMENT_BASE_URL = new InjectionToken<string>('APP_ENVIRONMENT_BASE_URL');
export const MENU_ITEM_ROUTES = new InjectionToken<string>('MENU_ITEM_ROUTES');
export const SUPPORT_ORG_ID_IN_URL = new InjectionToken<boolean>('SUPPORT_ORG_ID_IN_URL');
export const DEVELOPING_ON_LOCALHOST = new InjectionToken<boolean>('DEVELOPING_ON_LOCALHOST');
export const APP_ORG_ADMIN_FLAG = new InjectionToken<boolean>('APP_ORG_ADMIN_FLAG');

export const GOOGLE_MAPS_API_KEY_XGUUD = 'AIzaSyDeFPhXhwp-9k9ORh1v0tWMTT4Z2dw0Wyc';
export const GOOGLE_MAPS_API_KEY_HEALTHPOINT = 'AIzaSyB4LzE8SsCWv1Uz8EaqkwGT2l4xErv8-EU';

export type ValueOf<T> = T[keyof T];

/**
 * Media queries based off of Bootstrap's official dimensions
 */
export const mediaQueries = {
  xs: 480,
  md: 768,
  lg: 992,
  xl: 1200,
  'never show': 999999,
} as const;
