<div #headerActions class="header-actions">
  <div class="title" #title>
    <ng-content select=[title]>
    </ng-content>
  </div>
  <div class="search" #search>
    <ng-content select=[search]>
    </ng-content>
  </div>
  <div class="actions" #actions>
    <ng-content select=[actions]>
    </ng-content>
  </div>
</div>
<div class="content-wrapper" [style.overflow-y]="contentWrapperOverflowY$ | async">
  <ng-content select=[content]>
  </ng-content>
</div>