import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { combineManagers } from 'g2i-ngrx-utils';
import { shellManager } from 'shared/store/shell-manager';

import { AppState } from './interfaces';
import { sharedManager } from './shared.manager';


export const managers = combineManagers({
  shell: shellManager,
  jobs: sharedManager

});

export const reducerToken = new InjectionToken<ActionReducerMap<AppState>>('ImplReducers');

export const getReducers = () => managers.reducers;
