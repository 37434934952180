import { ChangeDetectorRef, ElementRef, EventEmitter } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { getOrganizationIdFromUrl } from 'g2i-ng-auth';
import { BehaviorSubject, combineLatest, from, fromEvent, never, Observable, of } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

import { ProjectVehicleLease } from './api/schedule/api-schedule.responses';
import { mediaQueries, scripts } from './consts';
import { AppRouteData } from './modules/shell-app/consts';
import { shellManager } from './store/shell-manager';


/**
 * Adds a new script tag to the HTML document if it does not yet exist.
 *
 * @param scriptUrl the URL of the script to be added
 * returns a promise which resolves when the script content has downloaded.
 */
export const loadScript = (script: keyof typeof scripts): Observable<any> => {
  if (document.querySelectorAll(`[src="${scripts[script]}"]`).length) {
    return of(null);
  }
  return from(new Promise(resolve =>
    document.body.appendChild(Object.assign(document.createElement('script'), {
      type: 'text/javascript',
      src: scripts[script],
      onload: resolve,
    })),
  ));
};

/**
 * Removes a script that was previously added via loadScript()
 *
 * @param scriptUrl the URL of the script to be removed
 */
export const unloadScript = (script: keyof typeof scripts) => {
  const scriptElement = document.querySelectorAll(`[src="${scripts[script]}"]`);
  if (scriptElement.length) {
    document.body.removeChild(scriptElement[0]);
  }
};

/**
 * Similar to ChangeDetectorRef.detectChanges() except that the error is swallowed.
 * This is useful when running detectChanges on a component that is about to be destroyed.
 */
export const detectChanges = (changeDetectorRef: ChangeDetectorRef) => {
  try {
    changeDetectorRef.detectChanges();
  } catch (e) {
    // ignore unavoidable error:
    // ViewDestroyedError: Attempt to use a destroyed view: detectChanges
  }
};

export const getRouteLastChildData = (route: ActivatedRoute): AppRouteData => {
  let snapshot = route.snapshot;
  let finished = false;
  while (!finished) {
    const child = snapshot.firstChild;
    if (!child) {
      finished = true;
    } else {
      snapshot = child;
    }
  }
  return snapshot.data;
};

export const observeProperty = <T, K extends keyof T>(target: T, key: K) => {
  const subject = new BehaviorSubject<T[K]>(target[key]);
  Object.defineProperty(target, key, {
    get: () => subject.getValue() as T[K],
    set: (newValue: T[K]) => {
      if (newValue !== subject.getValue()) {
        subject.next(newValue);
      }
    }
  });
  return subject;
};

export const trackByKey = (index: number, item: { key: any }) => item.key;

export const trackByIndex = (index: number) => index;

/**
 * Redirects to the error page without changing the route.
 * Usually this will be called when there are errors resolving data in the route resolver.
 */
export const navigateToErrorPage = (router: Router, requestedUrl: string, errorCode: number) => {
  shellManager.dispatch.resolverErrorReported(__filename, { code: errorCode });
  const orgId = getOrganizationIdFromUrl(requestedUrl);
  const errorUrl = orgId ? `/app/${orgId}/error` : '/app/error';
  router.navigateByUrl(errorUrl, { replaceUrl: false, skipLocationChange: true });
  window.history.pushState(null, null, requestedUrl);
};

export const moveActionsIntoPageHeader = (headerContent: ElementRef<HTMLDivElement>) => {
  const pageHeader = document.getElementById('page-header-actions');
  const parent = headerContent.nativeElement.parentElement;
  if (pageHeader && parent) {
    pageHeader.appendChild(parent.removeChild(headerContent.nativeElement));
  }
};

export const removePageActionsFromPageHeader = (headerContent: ElementRef<HTMLDivElement>) => {
  const pageHeader = document.getElementById('page-header-actions');
  if (pageHeader && headerContent) {
    pageHeader.removeChild(headerContent.nativeElement);
  }
};

export const getRouteParams = (snapshot: ActivatedRouteSnapshot) => {
  // Walk up the route tree
  while (snapshot.parent) {
    snapshot = snapshot.parent;
  }
  // Walk down the route tree and accumulate a map of route params
  const result = {};
  while (!!snapshot.firstChild) {
    Object.assign(result, snapshot.firstChild.params);
    snapshot = snapshot.firstChild;
  }
  return result;
};

/**
 * Calculates the distance in km between two sets of coordinates
 */
export const getDistanceFromLatLonInKm = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const deg2rad = (deg: number) => deg * (Math.PI / 180);
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);  // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

export const dateRangeUserFriendly = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
  const yearsAreTheSame = start.get('year') === end.get('year');
  const monthsAreTheSame = start.get('month') === end.get('month');
  return (yearsAreTheSame && monthsAreTheSame) ? `${start.format('DD')} - ${end.format('DD')} ${start.format('MMM YYYY')}`
    : yearsAreTheSame ? `${start.format('DD MMM')} - ${end.format('DD MMM')} ${start.format('YYYY')}`
      : `${start.format('DD MMM YYYY')} - ${end.format('DD MMM YYYY')}`;
};

export const dateRangeUserFriendlyWithoutYear = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
  const monthsAreTheSame = start.isSame(end, 'month');
  const datesAreTheSame = start.isSame(end, 'date');
  return datesAreTheSame ? start.format('DD MMM')
    : monthsAreTheSame ? `${start.format('DD')} - ${end.format('DD')} ${start.format('MMM')}`
      : `${start.format('DD MMM')} - ${end.format('DD MMM')}`;
};

export const getHourFromTimepicker = (value: string) => +value.substring(0, 2);
export const getMinFromTimepicker = (value: string) => +value.substring(3, 5);


type FunctionParameter<T> = T extends (arg: infer H) => any ? H : never;
type ClassObservables<T> = {
  [I in keyof T]: T[I] extends Observable<any> ? FunctionParameter<Parameters<T[I]['subscribe']>[0]> : never;
};
type SubType<Base, Condition> = Pick<Base, {
  [Key in keyof Base]: Base[Key] extends Condition ? Key : never
}[keyof Base]>;
type Observables<T> = ClassObservables<SubType<Omit<T, 'observables$'>, Observable<any>>>;

/**
 * Takes a component instance, finds all its observables, and combines them into 1 observable for the template to consume.
 * This has 3 benefits:
 * 1. It reduces the number of async pipes in the component templates to 1
 * 2. It provides synchronous access to observable values in the component code
 * 3. It makes observable values visible within the Angular Devtools extension
 *
 * NOTE: This function must be invoked AFTER all other observables (see example below)
 *
 * @example
 * ```
 * <ng-container *ngIf="observables$ | async; let observe;">
 *   <div>Observable 1: {{observe.observable1$}}</div>
 *   <div>Observable 2: {{observe.observable2$}}</div>
 * </ng-container>
 *
 * class MyComponent {
 *   readonly observable1$ = ...;
 *   readonly observable2$ = ...;
 *   readonly observables$ = combineComponentObservables<MyComponent>(this);
 *
 *   ngOnInit() {
 *     // synchronous access to observable values
 *     const firstObservableValue = this.$observables.value;
 *   }
 * }
 * ```
 */
export const combineComponentObservables = <T>(component: T): Observable<Observables<T>> & { value: Observables<T> } => {
  const keysOfObservableMembers = Object.keysTyped(component)
    .filter(key => component[key] instanceof Observable && !(component[key] instanceof EventEmitter));
  const res = combineLatest(
    keysOfObservableMembers.map(key => (component[key] as any as Observable<any>).pipe(startWith(undefined)))
  ).pipe(
    map(observers => {
      const result = {} as { [key in keyof T]: any };
      observers.forEach((obs, idx) => result[keysOfObservableMembers[idx]] = obs);
      (component as any).$observables = result;
      (res as any).value = result;
      return result as Observables<T>;
    })
  );
  return res as Observable<Observables<T>> & { value: Observables<T> };
};

type DecisionResult<X, H> = X extends (string | number | boolean | symbol | Record<string, unknown>) ? X : H;
export const decide = <X, T extends { when(): boolean; then(): X }>(decisions: T[]): DecisionResult<X, ReturnType<T['then']>> =>
  decisions.find(d => d.when()).then() as any;

export const screenWidthIs = (type: 'more than' | 'less than', query: (keyof typeof mediaQueries) | number) => fromEvent(window, 'resize')
  .pipe(
    debounceTime(0),
    startWith({}),
    map(() => {
      const breakPoint = mediaQueries[query as keyof typeof mediaQueries] || query as number;
      return type === 'more than' ? window.innerWidth > breakPoint : window.innerWidth < breakPoint;
    }),
  );

export const setMapCenterToSouthAfrica = (gmap: google.maps.Map) => {
  gmap.setCenter({
    lat: -30.5595,
    lng: 22.9375,
  });
  gmap.setZoom(7);
};

export const locationsHaveSameCoordinates = (
  loc1: { lat: number; lng: number },
  loc2: { lat: number; lng: number },
) => {
  const roundCoords = (arg: number) => {
    const [num, dec] = arg.toString().split('.');
    return `${num}.${dec.substring(0, 5)}`;
  };
  const activeLat = roundCoords(loc1.lat);
  const activeLng = roundCoords(loc1.lng);
  const setupLat = roundCoords(loc2.lat);
  const setupLng = roundCoords(loc2.lng);
  return activeLat === setupLat && activeLng === setupLng;
};

/**
 * Generates a URL for an application based on the current url.
 * i.e. If the current app is on DEV, the url generated will be for DEV as well.
 * Note: Localhost will default to DEV.
 *
 * @param appUrl string for the production url for an app
 * @returns string
 */
export const getAppEnvironmentUrl = (appUrl: string) => {
  const hostname = window.location.hostname;
  if (hostname.startsWith('d.')) {
    return `https://d.${appUrl}`;
  }
  if (hostname.startsWith('s.')) {
    return `https://s.${appUrl}`;
  }
  if (hostname.startsWith('localhost')) {
    return `https://d.${appUrl}`;
  }
  return `https://${appUrl}`;
};

export const getProjectVehicleLeaseForLeaseId = (projectVehicleLeases: ProjectVehicleLease[], leaseId: string) =>
  projectVehicleLeases.find(pvl =>
    pvl.vehicleLeaseId === leaseId &&
    pvl.dateDeleted === null &&
    dayjs(pvl.startDate).isSameOrBefore(dayjs()) &&
    dayjs(pvl.endDate).isSameOrAfter(dayjs()));
