import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as prod from './environments/environment.prod';
import * as staging from './environments/environment.staging';
import * as develop from './environments/environment.develop';

// avoid compilation warnings that these files are not being used
const unusedFiles = [prod, staging, develop];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
