<app-shell-page>
  <ng-container title>
    <!-- Page title here will be inserted into header -->
  </ng-container>
  <ng-container search>
    <!-- Designed for searchable pages, check an existing page for an example -->
  </ng-container>
  <ng-container actions>
    <!-- Any actions here will be inserted into the header on the right -->
  </ng-container>
  <div content class="shell-page">
    <!-- Content of page goes here -->
    <g2i-user-profile (saved)="onSaved()"></g2i-user-profile>
  </div>
</app-shell-page>