import { manageStateSlice } from 'g2i-ngrx-utils';
import { AcceptedJob, Job, JobTodosSummary } from '../api/schedule-personal/api-schedule-personal.responses';

export const sharedManager = manageStateSlice({
  jobOffers: new Array<Job>(),
  incompleteJobs: new Array<AcceptedJob>(),
  completedJobs: new Array<AcceptedJob>(),
  completedJobsScrollPos: 0,
  jobTodosSummary: { jobSlots: [], jobTodos: [], places: [] } as JobTodosSummary,
})({
  jobOffers: (state, payload: Job[]) => {
    state.jobOffers = payload;
  },
  incompleteJobs: (state, payload: AcceptedJob[]) => {
    state.incompleteJobs = payload;
  },
  incompleteJobsAppend: (state, payload: AcceptedJob[]) => {
    state.incompleteJobs.push(...payload);
  },
  completedJobs: (state, payload: AcceptedJob[]) => {
    state.completedJobs = payload;
  },
  completedJobsScroll: (state, payload: number) => {
    state.completedJobsScrollPos = payload;
  },
  completedJobsReplace: (state, payload: AcceptedJob[]) => {
    state.completedJobs = payload;
  },
  completedJobsAppend: (state, payload: AcceptedJob[]) => {
    state.completedJobs.push(...payload);
  },
  removeJobOffer: (state, payload: string) => {
    const index = state.jobOffers.findIndex(jo => jo.jobApplicationId === payload);
    state.jobOffers.splice(index, 1);
  },
  jobTodosSummarySet: (state, payload: JobTodosSummary) => {
    state.jobTodosSummary = payload;
  },
  jobTodosSummaryToggleTodo: (state, payload: { index: number; date: string }) => {
    state.jobTodosSummary.jobTodos[payload.index].jobTodo.dateCompleted = payload.date;
  },
  jobTodosSummaryToggleJobSlotStart: (state, payload: { index: number; date: string }) => {
    state.jobTodosSummary.jobSlots[payload.index].jobSlot.dateStarted = payload.date;
  },
  jobTodosSummaryToggleJobSlotEnd: (state, payload: { index: number; date: string }) => {
    state.jobTodosSummary.jobSlots[payload.index].jobSlot.dateCompleted = payload.date;
  },
});
