import { Injectable, Injector } from '@angular/core';
import { ApiBaseService } from 'shared/api/api-base.service';

import {
  DayScheduleSummaries,
  Schedule,
  SchedulesSummaryBounds,
  SchedulesSummaryListItem,
  SchedulesSummarySchedule,
  ScheduleSummary,
} from '../api-schedule.responses';
import {
  DayScheduleSummariesListRequest,
  ScheduleByKeyFindRequest,
  ScheduleCancelRequest,
  ScheduleCreateRequest,
  ScheduleListRequest,
  SchedulesSummaryBoundsRequest,
  SchedulesSummaryListRequest,
  SchedulesSummaryDetailRequest,
  ScheduleStatusUpdateRequest,
  ScheduleUpdateRequest,
  SchedulesSummaryDetailServiceBlockRequest,
} from './api-schedule.requests';

@Injectable()
export class ApiScheduleService extends ApiBaseService {

  constructor(
    injector: Injector,
  ) {
    super(() => `${this.serviceUrl('schedule')}/api/schedule/${this.routeParam('organizationid')}`, injector);
  }

  scheduleList = (request: ScheduleListRequest) =>
    this.request<ScheduleSummary[]>(``, 'GET', request);
  scheduleCreate = (request: ScheduleCreateRequest) =>
    this.request<Schedule>(``, 'POST', request);
  scheduleUpdate = (request: ScheduleUpdateRequest) =>
    this.request<Schedule>(``, 'PUT', request);
  scheduleFind = ({ scheduleId }: ScheduleByKeyFindRequest) =>
    this.request<Schedule>(scheduleId, 'GET');
  scheduleStatusUpdate = (request: ScheduleStatusUpdateRequest) =>
    this.request<Schedule>(`${request.id}/schedule-status`, 'PUT', request);
  scheduleCancel = ({ scheduleId }: ScheduleCancelRequest) =>
    this.request<Schedule>(scheduleId, 'DELETE');
  dayScheduleSummariesList = ({ projectId, date }: DayScheduleSummariesListRequest) =>
    this.request<DayScheduleSummaries>(`/day-summary/${projectId}/${date}`, 'GET');
  schedulesSummaryList = ({ projectId, startDate, endDate, includeTimes }: SchedulesSummaryListRequest) =>
    this.request<SchedulesSummaryListItem[]>(
      `/schedules-summary/projectId/${projectId}/start/${startDate}/end/${endDate}/include-times/${includeTimes}`, 'GET');
  schedulesSummaryBounds = ({ projectId }: SchedulesSummaryBoundsRequest) =>
    this.request<SchedulesSummaryBounds>(`/schedules-summary-bounds/projectId/${projectId}`, 'GET');
  schedulesSummaryDetail = ({ scheduleId, startDate, endDate }: SchedulesSummaryDetailRequest) =>
    this.request<SchedulesSummarySchedule>(`/schedules-summary-detail/schedule/${scheduleId}/start/${startDate}/end/${endDate}`, 'GET');
  schedulesSummaryDetailServiceBlock = ({ serviceBlockId }: SchedulesSummaryDetailServiceBlockRequest) =>
    this.request<SchedulesSummarySchedule>(`/schedules-summary-detail-service-block/${serviceBlockId}`, 'GET');

}
