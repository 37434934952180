import { Injectable, Injector } from '@angular/core';
import { ApiBaseService } from 'shared/api/api-base.service';
import { JobSlot, JobTodo, ServiceBlockSummary } from 'shared/api/schedule/api-schedule.responses';

import {
  AcceptJobOfferRequest,
  DeclineJobOfferRequest,
  ListCompletedJobRequest,
  ServiceBlockSummaryRequest,
  ToggleJobSlotRequest,
  ToggleJobTodoRequest,
} from './api-schedule-personal.requests';
import { AcceptedJob, Job, JobTodosSummary } from './api-schedule-personal.responses';

@Injectable()
export class ApiSchedulePersonalService extends ApiBaseService {

  constructor(
    injector: Injector,
  ) {
    super(() => `${this.serviceUrl('schedule')}/api/schedule/personal`, injector);
  }

  listJobOffersForUser = () =>
    this.request<Job[]>('job-offer', 'GET');
  acceptJobOffer = ({ jobApplicationId }: AcceptJobOfferRequest) =>
    this.request<AcceptedJob>(`job-offer/accept/${jobApplicationId}`, 'POST');
  declineJobOffer = ({ jobApplicationId }: DeclineJobOfferRequest) =>
    this.request<void>(`job-offer/decline/${jobApplicationId}`, 'POST');
  listIncompleteJobs = () =>
    this.request<AcceptedJob[]>('job/incomplete', 'GET');
  listCompletedJobs = (request: ListCompletedJobRequest) =>
    this.request<AcceptedJob[]>('job/completed', 'GET', request);
  getJob = (jobId: string) =>
    this.request<AcceptedJob>(`job/${jobId}`, 'GET');
  listTodosSummaryForJob = (jobId: string) =>
    this.request<JobTodosSummary>(`todo/job/${jobId}`, 'GET');
  toggleJobTodo = (jobTodoId: string, request: ToggleJobTodoRequest) =>
    this.request<JobTodo>(`jobTodo/${jobTodoId}/toggle`, 'POST', request);
  toggleJobSlotStarted = (jobSlotId: string, request: ToggleJobSlotRequest) =>
    this.request<JobSlot>(`jobSlot/${jobSlotId}/start`, 'POST', request);
  toggleJobSlotCompleted = (jobSlotId: string, request: ToggleJobSlotRequest) =>
    this.request<JobSlot>(`jobSlot/${jobSlotId}/complete`, 'POST', request);
  serviceBlockSummary = ({ serviceBlockId }: ServiceBlockSummaryRequest) =>
    this.request<ServiceBlockSummary>(`service-block-summary/${serviceBlockId}`, 'GET');

}
