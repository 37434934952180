import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { G2iAuthService, getRouteParam } from 'g2i-ng-auth';
import { G2iMediaSupportServiceOnline } from 'g2i-ng-media-control';
import { combineLatest } from 'rxjs';
import { take, tap } from 'rxjs/operators';


@Injectable()
export class MainResolver implements Resolve<any> {

  constructor(
    private authService: G2iAuthService
  ) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return combineLatest([
      this.authService.authDetails$.pipe(
        take(1),
        tap(authDetails => {
          // Use first available organization listed.
          const orgId = authDetails.user.organizations[0].organization.id;
          G2iMediaSupportServiceOnline.init({ orgId, token: authDetails.bbToken });
        }),
      ),
    ]);
  }
}
