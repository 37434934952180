import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';

import { ShellPageComponent } from './shell-page.component';

@NgModule({
  declarations: [
    ShellPageComponent,
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    RouterModule,
    MatIconModule,
  ],
  exports: [
    ShellPageComponent,
  ],
})
export class ShellPageModule {
}
