import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { G2iAuthService } from 'g2i-ng-auth';

@Injectable()
export class ShellAppGuard implements CanActivate {

  constructor(
    private readonly authService: G2iAuthService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isAuthenticated();
  }

}
