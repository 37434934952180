import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { STATE_MANAGERS } from 'shared/consts';
import { ShellStateManagers } from 'shared/store/interfaces';
import { shellManager } from 'shared/store/shell-manager';

@Component({
  selector: 'app-shell-profile-update',
  templateUrl: './shell-profile-update.component.html',
  styleUrls: ['./shell-profile-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellPageProfileUpdateComponent implements OnDestroy {

  readonly subscriptions: Subscription[] = [];

  constructor(
    private matSnackbar: MatSnackBar,
    @Inject(STATE_MANAGERS)
    private readonly stateManagers: ShellStateManagers,
  ) {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onSaved() {
    shellManager.dispatch.refreshSideMenuImage(__filename, !this.stateManagers.currentState().shell.refreshSideMenuImage);
    this.matSnackbar.showSuccess('Profile saved');
  }

}
