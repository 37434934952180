import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { ShellSnackbarComponent } from './snackbar.component';

@NgModule({
  declarations: [
    ShellSnackbarComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatSnackBarModule,
  ],
  exports: [
    ShellSnackbarComponent,
  ]
})
export class ShellSnackbarModule { }
