import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { G2iAuthModule, G2iLoaderModule } from 'g2i-ng-auth';

import { ShellPageModule } from '../shell-page/shell-page.module';
import { ShellProfileUpdateModule } from '../shell-profile-update/shell-profile-update.module';
import { ErrorService } from './error.service';
import { ShellAppGuard } from './shell-app.guard';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    G2iLoaderModule,
    G2iAuthModule,
    RouterModule,
    ShellPageModule,
    ShellProfileUpdateModule,
  ],
  exports: [
  ],
  providers: [
    ShellAppGuard,
    {
      provide: ErrorHandler,
      useClass: ErrorService,
    },
  ]
})
export class ShellAppModule {
}
