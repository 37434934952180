import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { G2iUserProfileModule } from 'g2i-ng-auth';

import { ShellPageModule } from '../shell-page/shell-page.module';
import { ShellPageProfileUpdateComponent } from './shell-profile-update.component';

@NgModule({
  declarations: [
    ShellPageProfileUpdateComponent,
  ],
  imports: [
    CommonModule,
    G2iUserProfileModule,
    ShellPageModule,
  ],
  exports: [
    // ShellPageProfileUpdateComponent,
  ],
})
export class ShellProfileUpdateModule {
}
