export const services = [
  'auth',
  'organization',
  'place',
  'asset',
  'solution',
  'media',
  'gencap',
  'schedule',
  'staff',
  'logging',
  'reporting',
] as const;

export const providerOverrides = {
  // auth: 'http://localhost:8202',
  // organization: 'http://localhost:8102',
  // place: 'http://localhost:8203',
  // asset: 'http://localhost:8204',
  // solution: 'http://localhost:8205',
  // media: 'http://localhost:8202',
  // gencap: 'http://localhost:8201',
  // schedule: 'http://localhost:8206',
  // staff: 'http://localhost:8207',
  // logging: 'http://localhost:8198',
  // reporting: 'http://localhost:8208',
} as { [key in typeof services[number]]: string };

// export const environmentOverride = 'https://p.services.g2i.io';



// export const services = [
//   'auth',
//   'organization',
//   'place',
//   'asset',
//   'solution',
//   'media',
//   'gencap',
//   'schedule',
//   'staff',
//   'logging',
//   'reporting',
// ] as const;

// export const providerOverrides = {
//   // auth: 'http://localhost:8202',
//   organization: 'http://localhost:8102',
//   place: 'http://localhost:8203',
//   asset: 'http://localhost:8204',
//   solution: 'http://localhost:8205',
//   media: 'http://localhost:8202',
//   // gencap: 'http://localhost:8201',
//   schedule: 'http://localhost:8206',
//   staff: 'http://localhost:8207',
//   // logging: 'http://localhost:8198',
//   // reporting: 'http://localhost:8208',
// } as { [key in typeof services[number]]: string };

// // export const environmentOverride = 'https://p.services.g2i.io';
export const environmentOverride = '';

